@mixin break($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin breakMin($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}
