$width: 1280px;

$input-bg: #f3f4f6;
$bg-light: #f5f5f5;
$red: #ff0035;
$red_light: #ff003327;
$light-red: #ffe5eb;
$dark-red: #ad0024;
$light-grey: #e5e5e5;
$form-outline: #e5e7eb;
$black: #1f2937;
$white: #fff;
$dark: #111827;
$grey: #6b7280;
$grey-light: #d1d5db;
$grey-dark: #4b5563;
$card-hover-color: rgb(243, 244, 246);
$background-grey: #eee;

$footer-color: #9ca3af;
