/* You can add global styles to this file, and also import other style files */
@forward "framework";
@forward "card";
@forward "swiper/scss";
@use "functions" as *;
@use "variables" as *;
@import "primeicons/primeicons.css";

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter-Regular.woff2') format('woff2');
    font-style: normal;
}

html,
body {
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100dvh;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
}

h1 {
    font-weight: 800;
}

h2 {
    color: $black;
}

img {
    content-visibility: auto;
}

.p-icon {
    width: auto !important;
    transform-origin: center;
    margin: 0 !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center !important;
    vertical-align: middle !important;
}

* {
    border-radius: 0 !important;
}

.p-textarea {
    height: 15rem; 
    overflow: auto; 
    resize: none;
}

.p-select-label {
    display: inline-flex !important;
    align-items: center !important;
    vertical-align: middle !important;
}

mwl-confirmation-popover-window {
    z-index: 1;

    .popover {
        background-color: white;
        padding: 1rem;
        border: 1px solid $grey;

        .confirm-btns {
            margin-top: 1rem;
            word-break: initial !important;

            .confirm-btn-container {
                flex-basis: 0%;
            }

            .btn-outline-secondary {
                color: #1f2937;
                border: solid 1px $form-outline;
            }
        }
    }

    h3 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }

    @media only screen and (max-width: 600px) {
        .popover {
            max-width: 250px;
        }
    }
}

textarea:focus,
input:focus {
    outline: none !important;
    box-shadow: 0 0 1px 1px rgb(231, 231, 231);
}

/* Custom video player */
.video-controls-container {
    .controls {
        .left {
            .volume {
                input {
                    cursor: pointer;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .left,
        .right {
            fa-icon {
                &:hover {
                    color: $light-grey;
                }
            }
        }
    }

    @include break(540px) {
        span {
            font-size: 12px;
        }

        .controls {
            padding: 0.75rem !important;

            .left .volume {
                display: flex;
                align-items: center;

                input {
                    width: 50px !important;
                    height: 4px;

                    &::-webkit-slider-thumb {
                        width: 3px !important;
                        height: 3px !important;
                    }
                }
            }
        }
    }
}

img:not(.image-loaded) {
    opacity: 0;
}

// hide the search input clear button
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

// hide the number input
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.list-view {

    app-video-item,
    app-author-item,
    app-event-item {
        &:nth-child(even) {
            background-color: rgba($bg-light, 0.85);
        }
    }
}

.ql-editor {
    font-family: Inter, sans-serif !important;
    font-weight: 600 !important;
}